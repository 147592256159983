import './i18nextConf';
import React, {Suspense} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from 'i18nextConf';
import { Context} from "components/context/Context";
import { useContext } from "react";
import Single from "views/examples/Blog/Single.js";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/ayx-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import Index from "views/Index.js";
import Login from "views/examples/LoginPage.js";
import Register from "views/examples/RegisterPage.js";
import AboutUs from "views/examples/AboutUs.js";
import GalleryPage from "views/examples/GalleryPage.js";
import Projects from "views/examples/Projects.js";
import Blog from "views/examples/Blog.js";
import Write from "views/examples/Blog/Write.js";
import Settings from "views/examples/Settings.js";


/*
const baseRouteUrl = "/:locale(es|en|eu|fr)?";
export const baseUrl = i18n.language === 'en' ? '' : '/'+ i18n.language;

console.log(baseUrl)
console.log(baseRouteUrl)*/
//const Routes = () => {
//  return (
  function App() {
  const { user } = useContext(Context);
  return (
  <Suspense fallback={(<div>Loading ~~~(</div>)}>
  <I18nextProvider i18n={i18n}>
  <Router>
    <IndexNavbar />
    <Switch>
        <Route path="/index" render={(props) => <Index {...props} />}            />
        <Route path="/about-us" render={(props) => <AboutUs {...props} />}       />            
        <Route path="/profile"  render={(props) => <Login {...props} />}       />
        <Route path="/gallery"  render={(props) => <GalleryPage {...props} />}   />
        <Route path="/projects"    render={(props) => <Projects {...props} />}   />
        <Route path="/blog"        render={(props) => <Blog {...props} />}       />
        <Route path="/videos"      render={(props) => <Register {...props} />}       />
        <Route path="/register"> {user ? <Blog /> : <Register/>} </Route> 
        <Route path="/login" >   {user ? <Blog/> : <Login/>} </Route> 
        <Route path="/write"  >  { user ? <Write /> : <Register/>} </Route> 
        <Route path="/settings"> { user ? <Settings /> : <Register />} </Route> 
        <Route path="/post/:postId"> <Single /></Route>
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
    </Switch>
  </Router>
  </I18nextProvider>
  </Suspense>
    );
}

export default App;