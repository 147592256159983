import axios from "axios";
import React, { PureComponent, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Context } from "components/context/Context";
import "views/examples/Blog/singlePost/singlePost.css";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { useHistory } from "react-router-dom";
//comment section import
import { CommentSection } from 'react-comments-section'
import data from "./data.json"
import 'react-comments-section/dist/index.css'
import CustomInput from "./customInput.js"


export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  const PF = "https://rebots.tk/images/";
  const { user } = useContext(Context);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  let history = useHistory();

  //comment section variables
  const [comment, setComment] = useState(data); //comment section hook TODO: change to mongo
  const signinUrl = "/login";
  const signupUrl = "/register";
  let count = 0;
  comment.map(i => {count+=1; i.replies && i.replies.map(i=> count+=1)} );


  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get("/posts/" + path);
      setPost(res.data);
      setTitle(res.data.title);
      setDesc(res.data.desc);
    };
    getPost();
  }, [path]);

  const handleDelete = async () => {
    try {
      await axios.delete(`/posts/${post._id}`, {
        data: { username: user.username },
      });
      history.push("/blog");
    } catch (err) {}
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/posts/${post._id}`, {
        username: user.username,
        title,
        desc,
      });
      setUpdateMode(false)
    } catch (err) {}
  };

  React.useEffect(() => {
    document.body.classList.add("gallery-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("gallery-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (

    <div className="singlePost">
      <div className="singlePostWrapper">
      <div className="center">
        {post.photo && (
          <img src={PF + post.photo} alt="" className="singlePostImg" />
        )}</div>
        {updateMode ? (
          <input
            type="text"
            value={title}
            className="singlePostTitleInput"
            autoFocus
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <h1 className="singlePostTitle">
            {title}
            {post.username === user?.username && (
              <div className="singlePostEdit">
                <i
                  className="singlePostIcon far fa-edit"
                  onClick={() => setUpdateMode(true)}
                ></i>
                <i
                  className="singlePostIcon far fa-trash-alt"
                  onClick={handleDelete}
                ></i>
              </div>
            )}
          </h1>
        )}
        <div className="singlePostInfo">
          <span className="singlePostAuthor">
            Author:
            <Link to={`/blog?user=${post.username}`} className="link">
              <b> {post.username}</b>
            </Link>
          </span>
          <span className="singlePostDate">
            {new Date(post.createdAt).toDateString()}
          </span>
        </div>
        {updateMode ? (
          <textarea
            className="singlePostDescInput"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        ) : (
          <p className="singlePostDesc">{desc}</p>
        )}
        {updateMode && (
          <button className="singlePostButton" onClick={handleUpdate}>
            Update
          </button>
        )}
        <hr className="vSpace"/>
        <div className="commentSection">
          <div className="header">{count} Comments</div>
            <CommentSection currentUser={user && { userId: user.username, avatarUrl: "https://ui-avatars.com/api/name="+ user.username+"&background=random", name: user.username }} commentsArray={comment}  
              setComment={setComment} signinUrl={signinUrl} signupUrl={signupUrl} customInput={CustomInput}/>
        </div>

      </div>
    </div>
  );
}
