import React from "react";
import { useTranslation } from "react-i18next";
import { Context } from "components/context/Context";

import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const { t, i18n } = useTranslation();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const navRef = React.useRef();
  const { user, dispatch } = React.useContext(Context);
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };
  const PF = "https://rebots.tk/images/"


  React.useEffect(() => {
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399 
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
          if(!document.body.classList.contains("gallery-page")){
            setNavbarColor("navbar-transparent");
          }else{
            setNavbarColor("");
          }
          
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos || window.pageYOffset < 80) {
        document.getElementById("navbar_main").style.top = "0";
      } else {
        document.getElementById("navbar_main").style.top = "-79px";
      }
      prevScrollpos = currentScrollPos;
    }
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };

    
  });

  function changeValue(str) {
    i18n.changeLanguage(str);
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  }

  
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar id="navbar_main" ref={navRef} className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container id="navbar_container">
          <div className="navbar-translate">
            <NavbarBrand href="/"  id="navbar-brand">
              <span id="logo">ReBots</span>
              <img
              alt="ReBots"
              id="nav-logo"
              src={require("assets/img/logo_W.png").default}
              ></img>
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
            <NavItem>
                <Button
                  className="nav-link btn-neutral"
                  color="info"
                  href="https://www.eurobots.net"
                  id="upgrade-to-pro"
                  target="_blank"
                >
                  <i className="ayx-icons shopping_cart-simple"></i>
                  <p>&nbsp;{t("BUY ROBOTS")}</p>
                </Button>
                <UncontrolledTooltip target="#upgrade-to-pro">
                {t("Visit us at Eurobots!")}
                </UncontrolledTooltip>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/blog"
                  id="blog-tooltip"
                >
                  <i className="ayx-icons education_paper"></i>
                  <p className="d-lg-none d-xl-none">Blog</p>
                </NavLink>
                <UncontrolledTooltip target="#blog-tooltip">
                  {t("Visit our BLOG!")}
                </UncontrolledTooltip>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/about-us"
                  id="aboutus-tooltip"
                >
                  
                  <i className="ayx-icons business_badge"></i>
                  <p className="d-lg-none d-xl-none">{t("About us")}</p>
                </NavLink>
                <UncontrolledTooltip target="#aboutus-tooltip">
                  {t("About us")}
                </UncontrolledTooltip>
              </NavItem>


              
                  
              <NavItem>
                <NavLink
                  href="/gallery"
                  id="gallery-tooltip"
                >
                  <i className="ayx-icons design_image"></i>
                  <p className="d-lg-none d-xl-none">{t("Gallery")}</p>
                </NavLink>
                <UncontrolledTooltip target="#gallery-tooltip">
                  {t("View gallery!")}
                </UncontrolledTooltip>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/about-us#contact"
                  id="contact-tooltip"
                >
                  <i className="ayx-icons ui-1_send"></i>
                  <p className="d-lg-none d-xl-none">{t("Contact us")}</p>
                </NavLink>
                <UncontrolledTooltip target="#contact-tooltip">
                  {t("Contact us!")}
                </UncontrolledTooltip>
              </NavItem>          

              {/*
              <NavItem>
                
                <NavLink
                  href="https://twitter.com/"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
                */}


              <NavItem>
                <NavLink
                  href="https://www.youtube.com/c/rebots"
                  target="_blank"
                  id="youtube-tooltip"
                >
                  <i className="fab fa-youtube"></i>
                  <p className="d-lg-none d-xl-none">Youtube</p>
                </NavLink>
                <UncontrolledTooltip target="#youtube-tooltip">
                {t("Subscribe on youtube")}
                </UncontrolledTooltip>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.facebook.com/rebots.tk"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  {t("Like us on Facebook")}
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/rebots.tk"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  {t("Follow us on Instagram")}
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={handleLogout} 
                >
                  <p>{user && "LOGOUT"}</p>
                </NavLink>

              </NavItem>
              
              {!user ? (
                  <><NavItem><NavLink
                      href="/login"
                    >
                      <i className="ayx-icons users_circle-08"></i>
                      <p className="d-lg-none d-xl-none">Login</p>
                    </NavLink></NavItem>
                    <NavItem><NavLink
                      href="/register"
                    >
                      <i className="ayx-icons objects_key-25"></i>
                      <p className="d-lg-none d-xl-none">Register</p>
                      </NavLink></NavItem></>
                ):(
                  <NavItem>
                  <NavLink
                    href="/settings"
                  >
                    <img className="topImg" src={PF+user.profilePic} alt="" />
                  </NavLink></NavItem>
                )}
                <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  nav
                >
                  <i className="ayx-icons business_globe">  </i>&nbsp;
                  <h6 id="lang">{t("Language")}</h6> 
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <div onClick={() => changeValue('en')}>English</div>
                  </DropdownItem>
                  <DropdownItem>
                    <div onClick={() => changeValue('es')}>Español</div>
                  </DropdownItem>
                  <DropdownItem>
                    <div onClick={() => changeValue('eu')}>Euskara</div>
                  </DropdownItem>
                  <DropdownItem>
                    <div onClick={() => changeValue('fr')}>Français</div>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
