import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import Carousel2 from "./index-sections/Carousel2.js";
import CompleteExamples from "./index-sections/CompleteExamples.js";
//import SignUp from "./index-sections/SignUp.js";
import Examples from "./index-sections/Examples.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
    <meta name="google-site-verification" content="Txy_Xgd7MPkUiXRG7o_Gno5TMg82uTAI3scpgCrl0Ks" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      {/* <IndexNavbar /> */}
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Carousel2 />
          <CompleteExamples />
          <Examples />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
