import "./blog.css";
import Sidebar from "views/examples/Blog/Sidebar.js";
import SinglePost from "views/examples/Blog/singlePost/SinglePost";

import DarkFooter from "components/Footers/DarkFooter.js";

export default function Single() {
  return (
    <>
{/* <IndexNavbar /> */}
      <div className="single">
        <SinglePost/>
        <Sidebar />
      </div>
      <DarkFooter />
    </>
  );
}