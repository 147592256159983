import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Post({ post }) {
  const PF = "https://rebots.tk/images/";
  let style = { "text-align": "center" };
  return (
    <div className="post">
      <Link to={`/post/${post._id}`} className="link">
      {post.photo && <div style={style}><LazyLoadImage effect="blur" className="postImg" src={PF + post.photo} alt="" /></div>}
      <div className="postInfo">
        <span className="postDate">
          {new Date(post.createdAt).toDateString()}
        </span>
          <span className="postTitle">{post.title}</span>
      </div>
      <p className="postDesc">{post.desc}</p>
      </Link>
      <div className="postCats">
          {post.categories.map((c) => (
            <span className="postCat">{c}</span>
          ))}
          
        </div>
    </div>
  );
}
