/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

function DarkFooter() {
  return (
    <footer id="dark_footer" className="footer" data-background-color="dark-blue">
      <Container className="text-center ">
        <Row id="footer_margin">
            <div className="col">
            <Row><a href="/">
                  Home
               </a></Row>
               <Row><a href="/Blog">
                  Blog
               </a></Row>
               <Row><a href="/about-us#contact">
                  Conctact us
               </a></Row>
               <Row><a href="/about-us">
                  About us
               </a></Row>
            </div>
            <div className="col">
              
              <Row><a href="https://www.linkedin.com/company/rebots" target="_blank">
                  LinkedIn
               </a></Row>
              <Row><a href="https://www.youtube.com/channel/UCFNcSWtfb23a1O0EvyoZjDw" target="_blank">
                  Youtube
               </a></Row>
               <Row><a href="https://www.facebook.com/rebots.tk/" target="_blank">
                  Facebook
               </a></Row>
               <Row><a href="https://www.instagram.com/rebots.tk/" target="_blank">
                  Instagram
               </a></Row>

            </div>
            <div className="col">
           
            <Row><a href="https://www.eurobots.net">
                Eurobots
              </a></Row>
              
              <Row> <a href="https://www.repair-robots.com/">
                  Repair Robots
              </a></Row>
              
              <Row><a href="https://celdasroboticas.com/">
                  INA celdas
              </a></Row>
              
              <Row><a href="https://avenco.com.tr/en/">
                  Avenco
              </a></Row>
            </div>

          </Row>
          </Container>
          &nbsp;

        <Container id="dark_footer">
        <nav>
          <ul>            
          <li>
          <a href="tel:+34944147995">TEL. +34 944147995 </a> | 
          <a href = "mailto: info@eurobots.net">info@eurobots.net</a>
          </li>

          </ul>
        </nav>

        <div className="copyright" id="copyright">
          ©{new Date().getFullYear()}, Designed & coded by{" "}
          <a
            href="/"
          >
            Ayxworks
          </a>
          .
        </div>
        
      </Container>
    </footer>
  );
}

export default DarkFooter;
