import React from "react";
import Gallery from 'react-grid-gallery';
import { useTranslation } from "react-i18next";// eslint-disable-next-line no-unused-vars
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

import { Container} from "reactstrap";
const IMAGES =
[  {
  src: "https://lh3.googleusercontent.com/6NHKoXdj8T_PigJgzlr64vUxt3JQY1dSaZcOQgVd1g5a6FGd9yvXzUo4z8Fm_OhdwxUfGXDuD6SV-FvCDNKPwbngSys-mhNtFLtwH0cF271jVMXEIne-lXWJtHZpsoayLirdZlYRLA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/6NHKoXdj8T_PigJgzlr64vUxt3JQY1dSaZcOQgVd1g5a6FGd9yvXzUo4z8Fm_OhdwxUfGXDuD6SV-FvCDNKPwbngSys-mhNtFLtwH0cF271jVMXEIne-lXWJtHZpsoayLirdZlYRLA=w1920-h1080",
  caption: "KUKA IIWA 14 R820 + OnRobot 3FG15 (Callaborative applications)"
},
{
  src: "https://lh3.googleusercontent.com/UYJYAvpYKpyHzrVzkJtWPssAEk-4J2Th_YptMxzm1RRuEN0Z_RY6K0hX6cWjwK4NtQWICW6dUz8MWOCIS03ySRLkcJQzFdNB7ffmOG9mPNCJRzRcihyOqnbz-c3Q797MXFe2SzDubw=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/UYJYAvpYKpyHzrVzkJtWPssAEk-4J2Th_YptMxzm1RRuEN0Z_RY6K0hX6cWjwK4NtQWICW6dUz8MWOCIS03ySRLkcJQzFdNB7ffmOG9mPNCJRzRcihyOqnbz-c3Q797MXFe2SzDubw=w1920-h1080",
  caption: "KUKA IIWA 14 R820 + OnRobot 3FG15 (Callaborative applications)"
},
{
  src: "https://lh3.googleusercontent.com/R4dV89HyICUKmiDF7219gHtYfXuJcummz4GXWV9pw_sjyE7vS7rAJRp72noTVjZaToi27EX1j14Pj45gz72KckxWPTRFvXOyIzaguqsGT17vHMKYVxo4V0qKjW5iS1mDaw3o4bxW7w=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/R4dV89HyICUKmiDF7219gHtYfXuJcummz4GXWV9pw_sjyE7vS7rAJRp72noTVjZaToi27EX1j14Pj45gz72KckxWPTRFvXOyIzaguqsGT17vHMKYVxo4V0qKjW5iS1mDaw3o4bxW7w=w1920-h1080",
  caption: "UR10 + TPS320i Semi-collaborative welding and LWR-4"
},
{
  src: "https://lh3.googleusercontent.com/4sCUJlexF3qy4AbjmfoOFYyhgobLlxuVEA8zEidLbGyNfx8m0ne19DoHBF7rghxMVjA6g8LquLQbKYWQOlnzH4LRKJlJ94zX_Nx6hwr1LvJyevJJ8wr3jlGP7Sk7RxoqtiIswTb4RQ=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/4sCUJlexF3qy4AbjmfoOFYyhgobLlxuVEA8zEidLbGyNfx8m0ne19DoHBF7rghxMVjA6g8LquLQbKYWQOlnzH4LRKJlJ94zX_Nx6hwr1LvJyevJJ8wr3jlGP7Sk7RxoqtiIswTb4RQ=w1920-h1080",
  caption: "UR10 + Fronius TPS320i"
},
{
  src: "https://lh3.googleusercontent.com/yWT-7BE5xf2FgQZ_he6GdEsl0AMnXTGclDCqTxpcSt7KiDhB05iqZ1uigAoQ60YjhJ584OOFNCVtXhi0HZahglEBQQ-Mdw5qlOJ0H3Srv0x7BOT_NS2or0zHOTk5gHdGpbwUAMRqAA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/yWT-7BE5xf2FgQZ_he6GdEsl0AMnXTGclDCqTxpcSt7KiDhB05iqZ1uigAoQ60YjhJ584OOFNCVtXhi0HZahglEBQQ-Mdw5qlOJ0H3Srv0x7BOT_NS2or0zHOTk5gHdGpbwUAMRqAA=w1920-h1080",
  caption: "LWR-4 + Beckhoff HMI"
},
{
  src: "https://lh3.googleusercontent.com/UlzaCOUbyE_5QQPsl7GDGlrFTu-TacBioFZ612KheVIFsWCS57faP81OTVI_vaYEnALXC6HagXR0-fHVFt0edS_4mlvjBfwFLxijFOHcCE_usN5dkMpkcTR2moqeJB5keAvtBRNqrw=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/UlzaCOUbyE_5QQPsl7GDGlrFTu-TacBioFZ612KheVIFsWCS57faP81OTVI_vaYEnALXC6HagXR0-fHVFt0edS_4mlvjBfwFLxijFOHcCE_usN5dkMpkcTR2moqeJB5keAvtBRNqrw=w1920-h1080",
  caption: "ABB 1200 + Yaskawa HC10 collaborative robot"
},
{
  src: "https://lh3.googleusercontent.com/kiWOkMQPWt7qSmp99C-c2Up9zdrZ6oaKpDpYZh3TpqxWVIOVNIdeqQV1bYLV-aZ_Pp5aUjhkVbFO8ajzAmy5o-yPfJfIaBJguT9W7HcStRI5N-RILj_AwG28AGA-sk7-Ka6LPJwpyA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/kiWOkMQPWt7qSmp99C-c2Up9zdrZ6oaKpDpYZh3TpqxWVIOVNIdeqQV1bYLV-aZ_Pp5aUjhkVbFO8ajzAmy5o-yPfJfIaBJguT9W7HcStRI5N-RILj_AwG28AGA-sk7-Ka6LPJwpyA=w1920-h1080",
  caption: "ABB1200 with linear track and suction tool"
  },
  {
    src: "https://lh3.googleusercontent.com/jDWCA5-ER1uaTjOQj6J-grxHcJAlSBAHJXK-7WAGKDOr7brUwpmyDUG-rV9btBHclaaKGeIZUM5P-Jl3sA3ayjb4ioPgnfENFvLkqJH14gUvUAZwYu96aSb8N6OnoQPaVluGRwRPmA=w1920-h1080",
    thumbnail: "https://lh3.googleusercontent.com/jDWCA5-ER1uaTjOQj6J-grxHcJAlSBAHJXK-7WAGKDOr7brUwpmyDUG-rV9btBHclaaKGeIZUM5P-Jl3sA3ayjb4ioPgnfENFvLkqJH14gUvUAZwYu96aSb8N6OnoQPaVluGRwRPmA=w1920-h1080",
    caption: "Yaskawa HC10+VG10"
  },
  {
    src: "https://lh3.googleusercontent.com/FFLjRXU2ZevRe57mLQ3TblLG9M8Y5_u3pdSdkMI84IqRUq4Oiw2RlIGANu1h5tv3y71AzXUZYc9UU2wrk8ZbJl9S9JfNS4ipL2GhlRRl4uwPSE9eWv8NUfo4vsAeUAEIMg6vekS5rw=w1920-h1080",
    thumbnail: "https://lh3.googleusercontent.com/FFLjRXU2ZevRe57mLQ3TblLG9M8Y5_u3pdSdkMI84IqRUq4Oiw2RlIGANu1h5tv3y71AzXUZYc9UU2wrk8ZbJl9S9JfNS4ipL2GhlRRl4uwPSE9eWv8NUfo4vsAeUAEIMg6vekS5rw=w1920-h1080",
    caption: "Bin-picking + conveyor tracking & sorting + palletizing example "
  },
  {
  src: "https://lh3.googleusercontent.com/iww1oNEaXm-VzyI3QY7ahgAYJwVslB8JFUZmLyDd12R_tMCORhDewrTkqX2PlhMHjUSm3lJk1vgYqVTm5KvaSD0GnstR7k0tR1hCzjZ06SZMhrPjqusNdNrXF09B03oz9j_SIFP8-A=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/iww1oNEaXm-VzyI3QY7ahgAYJwVslB8JFUZmLyDd12R_tMCORhDewrTkqX2PlhMHjUSm3lJk1vgYqVTm5KvaSD0GnstR7k0tR1hCzjZ06SZMhrPjqusNdNrXF09B03oz9j_SIFP8-A=w1920-h1080",
  caption: "Yaskawa HC10 + VG10 (Palletizing) and UR3 with OnRobot eyes and Soft gripper (vision tracking and sorting organic food)"
  },
  {
  src: "https://lh3.googleusercontent.com/Qq_drpCJAQc-TbgdIcACMdXlCGoFCqdfyIHcd2Vyev7EP-lpWmNIolp_VekJ0EeUv9Y-fQE20I-d7dk21Yndl8VSplz829tHcRSbkUz0oFd1LJ-oLB7zyv3lwiUBzkBgDkw-6JyfYA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/Qq_drpCJAQc-TbgdIcACMdXlCGoFCqdfyIHcd2Vyev7EP-lpWmNIolp_VekJ0EeUv9Y-fQE20I-d7dk21Yndl8VSplz829tHcRSbkUz0oFd1LJ-oLB7zyv3lwiUBzkBgDkw-6JyfYA=w1920-h1080",
  caption: "UR3 with OnRobot eyes and Soft gripper"
  },
{
  src: "https://lh3.googleusercontent.com/CwgLcM3u79-_oUW3UFdMrF-EYutyadQ14Nj4to4kUUH2FESze_MwfYXXO5H7dNi9t7z-B7QJ1NvrmHOnXFZVU44jASFDDGtTXXfCOiQV87jQxH0hHW022FseS9hYCvv-9sEdaHPuCA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/CwgLcM3u79-_oUW3UFdMrF-EYutyadQ14Nj4to4kUUH2FESze_MwfYXXO5H7dNi9t7z-B7QJ1NvrmHOnXFZVU44jASFDDGtTXXfCOiQV87jQxH0hHW022FseS9hYCvv-9sEdaHPuCA=w1920-h1080",
  caption: "Mitsubishi scara melfa"
},
{
  src: "https://lh3.googleusercontent.com/MFQ8Furn91uoQ0phNnr5oVB8_K3ywAO9Dr8qReuQoq-81MnM0ThDWTr595XHhIkWK6sQwyKUXeBEzTogIEJ1T0TP7eigqrKvEfqsLAMpmzC_hWeqtb-GpmLHXpeAmJGP_2EgUIg1MQ=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/MFQ8Furn91uoQ0phNnr5oVB8_K3ywAO9Dr8qReuQoq-81MnM0ThDWTr595XHhIkWK6sQwyKUXeBEzTogIEJ1T0TP7eigqrKvEfqsLAMpmzC_hWeqtb-GpmLHXpeAmJGP_2EgUIg1MQ=w1920-h1080",
  caption: "Mitsubishi scara melfa with camera C-track 780"
},
{
  src: "https://lh3.googleusercontent.com/cDH4iq2aGXOIyJTdWu788-TvhCrVS3vyObCktchISpk3J6KOh_BduEtOeVSHHCQ_DTENYFHqVCIMy_hdNPD2QMghHaDFlkp5R6cubYJ1or9ToctIRuJZNY_sHW1F9d85Z5bvGspFbQ=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/cDH4iq2aGXOIyJTdWu788-TvhCrVS3vyObCktchISpk3J6KOh_BduEtOeVSHHCQ_DTENYFHqVCIMy_hdNPD2QMghHaDFlkp5R6cubYJ1or9ToctIRuJZNY_sHW1F9d85Z5bvGspFbQ=w1920-h1080",
  caption: "KR10 + RG2"
},
{
  src: "https://lh3.googleusercontent.com/CuaIMseDHVP2GyW4RKvvNXXvdtdrGiyc-PHE5gQZF6EJdfBQ2ED_JFF1wlgYZqsRbEpBPgPik6aSn78O92VAVAUqQcwa2ttSRp37H0mYOFWTQ2ybCYk8nMT7PRt3Sskr05HF1Y0TCQ=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/CuaIMseDHVP2GyW4RKvvNXXvdtdrGiyc-PHE5gQZF6EJdfBQ2ED_JFF1wlgYZqsRbEpBPgPik6aSn78O92VAVAUqQcwa2ttSRp37H0mYOFWTQ2ybCYk8nMT7PRt3Sskr05HF1Y0TCQ=w1920-h1080",
  caption: "R&D LAB/Showroom"
},
{
  src: "https://lh3.googleusercontent.com/U3HSvuxXKUpvrbECFJl_EY0aijbxj6zpB-3Hq1N0bsZPWd8VwuTaKbmIY333H4XOL9HkujlNaSHEXHStNS_IjoHex5Cg-rs9Ef6q7WySsQWuxcVjOwCeCLyUscUZQLoFjLFISCfPWw=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/U3HSvuxXKUpvrbECFJl_EY0aijbxj6zpB-3Hq1N0bsZPWd8VwuTaKbmIY333H4XOL9HkujlNaSHEXHStNS_IjoHex5Cg-rs9Ef6q7WySsQWuxcVjOwCeCLyUscUZQLoFjLFISCfPWw=w1920-h1080",
  caption: "R&D LAB/Showroom wide angle"
},
{
  src: "https://lh3.googleusercontent.com/B04kaG0LS2t23uw0V6NHLz3AxET3exE1KIh_PnCtcHKvH-lWcNsm3vJSEIYmms1w9dgQ6n0EXxe8q4rN74Rcevk-evyUIIoonAO-jfMQNUcg8JwIqM1KJ1d8omHxdq6iYpYhKgts2A=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/B04kaG0LS2t23uw0V6NHLz3AxET3exE1KIh_PnCtcHKvH-lWcNsm3vJSEIYmms1w9dgQ6n0EXxe8q4rN74Rcevk-evyUIIoonAO-jfMQNUcg8JwIqM1KJ1d8omHxdq6iYpYhKgts2A=w1920-h1080",
  caption: "UR3 twin system"
},
{
src: "https://lh3.googleusercontent.com/eP71o7lkqx8fPrseeUnTt7MoSFP_JbnGMHmdzxzSJGOit_Pv1Cn6lq85I6DmuCwPZ0s5pUjQvWdI28KoX1oQ7lszs4U90RJ35QIuGbSL8UzNA4WOBY4_vUkFpQFMEvosBQfNrOApyQ=w1920-h1080",
thumbnail: "https://lh3.googleusercontent.com/eP71o7lkqx8fPrseeUnTt7MoSFP_JbnGMHmdzxzSJGOit_Pv1Cn6lq85I6DmuCwPZ0s5pUjQvWdI28KoX1oQ7lszs4U90RJ35QIuGbSL8UzNA4WOBY4_vUkFpQFMEvosBQfNrOApyQ=w1920-h1080",
caption: "Ender 3 v2 3D printer"
},
{
src: "https://lh3.googleusercontent.com/AeuxZOKH-oXxf68vVdj-Wj8mS9HSvDEyNIs2CtWtuza6G9T0ON2-4GjXcGRrcg0VPiW_Z-klQKghr6z3fqp1ls5wLIWVUxwghA-GuhgSsOkdjdadsAiEnUsXfhLVwHk83DDggsEuOA=w1920-h1080",
thumbnail: "https://lh3.googleusercontent.com/AeuxZOKH-oXxf68vVdj-Wj8mS9HSvDEyNIs2CtWtuza6G9T0ON2-4GjXcGRrcg0VPiW_Z-klQKghr6z3fqp1ls5wLIWVUxwghA-GuhgSsOkdjdadsAiEnUsXfhLVwHk83DDggsEuOA=w1920-h1080",
caption: "Halloween decoration on conveyor"
},
{
src: "https://lh3.googleusercontent.com/4SiTpOzAr8YkImfC-A5Ifj0PR8PFMIEcJS_WrHzzSDmrxqrtdug2cX2vluTmyU0ZZ9d-VbI9scy_w2oPh7Q5sW8J42IaQ-6Qc282E5o-3sQSypwRa0NW1qWIAI-Ugl42xy-KeGNxqA=w1920-h1080",
thumbnail: "https://lh3.googleusercontent.com/4SiTpOzAr8YkImfC-A5Ifj0PR8PFMIEcJS_WrHzzSDmrxqrtdug2cX2vluTmyU0ZZ9d-VbI9scy_w2oPh7Q5sW8J42IaQ-6Qc282E5o-3sQSypwRa0NW1qWIAI-Ugl42xy-KeGNxqA=w1920-h1080",
caption: "Halloween decoration on conveyor"
},
{
src: "https://lh3.googleusercontent.com/ecRwPt_qRMsVWURJ9AJKWl5A57LNt6UiNVJR0wPDms3luM2k_hN25CgpFeP5_IuhFYC5EIt4wJcNjXXgwV-T1BberlZtG0pYn4YOv0RM4FEHAWpc22hmGX9ZHksALhPbWPtacgIEag=w1920-h1080",
thumbnail: "https://lh3.googleusercontent.com/ecRwPt_qRMsVWURJ9AJKWl5A57LNt6UiNVJR0wPDms3luM2k_hN25CgpFeP5_IuhFYC5EIt4wJcNjXXgwV-T1BberlZtG0pYn4YOv0RM4FEHAWpc22hmGX9ZHksALhPbWPtacgIEag=w1920-h1080",
caption: "Halloween decoration on conveyor"
},
{
  src: "https://lh3.googleusercontent.com/il9orCmxvUJLBEYg7e-7VJbMUoRSNpM0_Qen4tXx11XOb7rK5t8bP4z7DdRJjsWULJ544sUCpW7ors1W1lNE5rEZFdGMcsaxzm3eL7eYAuMRDwvkV-7EQmRUNaCKN7HfTtmEqh9vDg=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/il9orCmxvUJLBEYg7e-7VJbMUoRSNpM0_Qen4tXx11XOb7rK5t8bP4z7DdRJjsWULJ544sUCpW7ors1W1lNE5rEZFdGMcsaxzm3eL7eYAuMRDwvkV-7EQmRUNaCKN7HfTtmEqh9vDg=w1920-h1080",
  caption: "Halloween decoration on conveyor close up"
},
{
  src: "https://lh3.googleusercontent.com/VBeYaYqzvDD-e-cNIGK0ujJuOgGkklvz9DH1_S1CCk6z1MosF4s2ErcPDjtwj3mKYIT8a8tD3V_WFvy-rswi0_JnuNXsB5kWFKBRgikmd6XxzXr6zSh-qOnXgDHY76Q1OlBVLzsIdA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/VBeYaYqzvDD-e-cNIGK0ujJuOgGkklvz9DH1_S1CCk6z1MosF4s2ErcPDjtwj3mKYIT8a8tD3V_WFvy-rswi0_JnuNXsB5kWFKBRgikmd6XxzXr6zSh-qOnXgDHY76Q1OlBVLzsIdA=w1920-h1080",
  caption: "Halloween decoration on UR5e close up"
},
{
  src: "https://lh3.googleusercontent.com/vhYp7Fts7DW9RbLX1lPc7lQmoOTSrVxISoalCJCZfA_wFbuP1tuvB_oAoneKxGnIsrCoaxrSeBr5g1S60qeTu3XLKssoOQCxpGT4cAsjUqrkH0HWU09uzPDKmw-5CM2GWEq_CoUtvg=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/vhYp7Fts7DW9RbLX1lPc7lQmoOTSrVxISoalCJCZfA_wFbuP1tuvB_oAoneKxGnIsrCoaxrSeBr5g1S60qeTu3XLKssoOQCxpGT4cAsjUqrkH0HWU09uzPDKmw-5CM2GWEq_CoUtvg=w1920-h1080",
  caption: "Halloween decoration on mitsubishi close up"
},
{
  src: "https://lh3.googleusercontent.com/SmCQ5DyDtlPlShgC7zYVgaOiN3ivT_f_DpC7rY0-XBU6u64DjQMKIMJn0jnUj1VAuT5GcBe6EHY1Yy-923jqzkRLlXx2m5BDUlCb8pM2G060fj78ufiWdgGW1BGlYdAaVLtRouMCvA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/SmCQ5DyDtlPlShgC7zYVgaOiN3ivT_f_DpC7rY0-XBU6u64DjQMKIMJn0jnUj1VAuT5GcBe6EHY1Yy-923jqzkRLlXx2m5BDUlCb8pM2G060fj78ufiWdgGW1BGlYdAaVLtRouMCvA=w1920-h1080",
  caption: "Halloween decoration on controllers close up"
},
{
  src: "https://lh3.googleusercontent.com/n7Vlry2qql_IxP0glnotNWMZIlHkgebVRpduzLRoAHQgYGcbjGcY06Z96iPxxiYPO4gre9HRYTXXDbpG8l1tdLbk5H2PAZz9Y0PGFmb0JS5aG9cBLVIHS1Up7IOgByYJsB3f-J7HVg=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/n7Vlry2qql_IxP0glnotNWMZIlHkgebVRpduzLRoAHQgYGcbjGcY06Z96iPxxiYPO4gre9HRYTXXDbpG8l1tdLbk5H2PAZz9Y0PGFmb0JS5aG9cBLVIHS1Up7IOgByYJsB3f-J7HVg=w1920-h1080",
  caption: "Halloween decoration on KR10 close up"
},
{
  src: "https://lh3.googleusercontent.com/NbCAq6dEIVacJ2lO4Oxy2KSS9yvtcgZL4m94yVOt_Zvakn4h0MlD9w9H_NMsCTGs63oWwL9DOOva7-xczAELO0oOqzPOxKiCf7cv14e_qBt_Cf_0uBwBlBD-2NA2946xmKU4y2OslQ=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/NbCAq6dEIVacJ2lO4Oxy2KSS9yvtcgZL4m94yVOt_Zvakn4h0MlD9w9H_NMsCTGs63oWwL9DOOva7-xczAELO0oOqzPOxKiCf7cv14e_qBt_Cf_0uBwBlBD-2NA2946xmKU4y2OslQ=w1920-h1080",
  caption: "Halloween eurobots HMI bloody"
},
{
  src: "https://lh3.googleusercontent.com/bfe1awF1tMTSW3wOnyGcG8oEz9pcAy8PEJhb8GB81JxNAd9ThbYJjHvY2tswMoNC91JVFMPXexmt7TWH0pUrypPLF1T1uomb-w6fMBpm73xt1WEyO6TIyf_JHC2BGLqYxrvRfy1K0Q=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/bfe1awF1tMTSW3wOnyGcG8oEz9pcAy8PEJhb8GB81JxNAd9ThbYJjHvY2tswMoNC91JVFMPXexmt7TWH0pUrypPLF1T1uomb-w6fMBpm73xt1WEyO6TIyf_JHC2BGLqYxrvRfy1K0Q=w1920-h1080",
  caption: "Halloween trick & treat IIWA"
},
{
  src: "https://lh3.googleusercontent.com/lamZU1DThMDRwyzDXkrKFg0OhQONQIO1_8H3Q9TRu_zLpzO481bvSo-C6vbWz4GFDFwaSIYsyKHOB28DFx5pwwj4vMAoP4Q5DP_EZv7eDLG7fZcTnnMTf9PFpGSSF9Y19RZY7Rowfw=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/lamZU1DThMDRwyzDXkrKFg0OhQONQIO1_8H3Q9TRu_zLpzO481bvSo-C6vbWz4GFDFwaSIYsyKHOB28DFx5pwwj4vMAoP4Q5DP_EZv7eDLG7fZcTnnMTf9PFpGSSF9Y19RZY7Rowfw=w1920-h1080",
  caption: "KUKA IIWA 14 R820"
},
{
  src: "https://lh3.googleusercontent.com/mpR9rQ9M9NPKez8bfQXeet-2DNXTF2cnkCEDTPFGDAhttqqewj4jJBpejAEvgu-oarauTAv_lEfX6kWZAPE8UEJvlkDRwn4_QjIzUvWP3gToJJ6k2kd3ZMuGX-M-qEJITkQigMTDRg=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/mpR9rQ9M9NPKez8bfQXeet-2DNXTF2cnkCEDTPFGDAhttqqewj4jJBpejAEvgu-oarauTAv_lEfX6kWZAPE8UEJvlkDRwn4_QjIzUvWP3gToJJ6k2kd3ZMuGX-M-qEJITkQigMTDRg=w1920-h1080",
  caption: "Our first robots in the lab"
},
{
  src: "https://lh3.googleusercontent.com/V0pD_s6pd_OrAxqK5mzlBrnSZ1KOrQetIqYDQq2m6FTY7hJfjEMmdnwHJxBjSH45Q2wYoHu-VwtZr8puUcBILzJ1_5vre-5JQ5Gmor9pDlhlev_K3ahfUrSuJsdlo2Z_JaWf49wgmA=w1920-h1080",
  thumbnail: "https://lh3.googleusercontent.com/V0pD_s6pd_OrAxqK5mzlBrnSZ1KOrQetIqYDQq2m6FTY7hJfjEMmdnwHJxBjSH45Q2wYoHu-VwtZr8puUcBILzJ1_5vre-5JQ5Gmor9pDlhlev_K3ahfUrSuJsdlo2Z_JaWf49wgmA=w1920-h1080",
  caption: "Start of the showroom november 2020"
}
]


function styleSmall(){
  //console.log(window.innerWidth)
  if (window.innerWidth<992){
    return ({
      'width':'100%',
      'height':'100%',
    });
  }else{
    return ({
      'width':'auto',
      'height':'300px',

    });
  }
} 
/*
dentro de las propiedades de galeria
thumbnailStyle={styleSmall}
*/

function GalleryPage() {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  React.useEffect(() => {
    document.body.classList.add("gallery-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.getElementById("navbar_main").classList.remove("navbar-transparent");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("gallery-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const alt = "photo";
  const style = { width:"100%", height:"480px", display:"none" };
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <script src="https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js" async></script>
      {/* <IndexNavbar /> */}
      <div className="wrapper">
      
        <Container>
        
        <div id="gallery">
          <Gallery 
            images={IMAGES}
            enableImageSelection={false}
            thumbnailStyle={styleSmall}
            tileViewportStyle={styleSmall}
          />
        </div>
        </Container>

        <DarkFooter />
      </div>
    </>
  );
}

export default GalleryPage;




