import React, {useState, useContext} from "react"
import { Context } from "components/context/Context";

const CustomInput=({cancellor,
  parentId,
  value,
  edit,
  submit,
  handleCancel,
  })=>{
    const [text, setText] = useState(value)

    const handleChange = (e) => {
        setText(e.target.value)
      }
    const { user } = useContext(Context);
    const PF = "https://rebots.tk/images/";
    return(  
      <form><div className="form">
        <div className="rowComment"> {user && (<img src={PF+user.profilePic} style={{width:80, height:80}}/>)}
          <div className="rowComment">
            <div className="arrow-left"></div>
            <div className="input-div"> 
            <span className="input-name">{user && user.username}</span>
            <textarea
                rows="2"
                className="input-box"
                type='text'
                placeholder='Type your reply here.'
                component='input'
                value={text}
                onChange={handleChange}></textarea>  
            </div>
          </div> 
        </div>
        <div className="btn-div">
          <button
          className="post-btn"
            onClick={() =>submit(cancellor, text, parentId, edit, setText)}
            type="submit" 
            disabled={!text}
          >
            Post
          </button>
            <button className="cancel-btn"
            onClick={() => handleCancel(cancellor,edit)}>
              Cancel
            </button>
        </div>
      </div></form>
      )
}

export default CustomInput