/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col  } from "reactstrap";
import { useTranslation } from "react-i18next";
//lazy image loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function IndexHeader() {
  let pageHeader = React.createRef();
  const { t, i18n} = useTranslation()

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/back/1.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <LazyLoadImage
              alt="..."
              className="n-logo"
              src={require("assets/img/logo_W.png").default}
            ></LazyLoadImage>
            
            <h1 id="title">EUROBOTS 4.0</h1>
          
            <h3 className="slogan">{t("slogan")}</h3>
          </div>
        </Container>
      </div>
      
      <div data-background-color="lightgrey" className="section section-images">
        <Container>
          <Row>
            <Col id="imgCol" md="12">
              <div className="hero-images-container">
                <LazyLoadImage
                  alt="..."
                  src={require("assets/img/group.png").default}
                ></LazyLoadImage>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
    
  );
}

export default IndexHeader;
