import "views/examples/Blog/blog.css";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import Sidebar from "views/examples/Blog/Sidebar.js";
import Posts from "views/examples/Blog/Posts.js";
import { useTranslation } from "react-i18next";// eslint-disable-next-line no-unused-vars
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

import { Container} from "reactstrap";

function Blog() {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  React.useEffect(() => {
    document.body.classList.add("gallery-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.getElementById("navbar_main").classList.remove("navbar-transparent");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("gallery-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const [posts, setPosts] = useState([]);
  const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get("/posts" + search);
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      {/* <IndexNavbar /> */}
      <div className="wrapper">
        <Container id="blogContainer">
          <div className="home">
            <Posts posts={posts} />
            <Sidebar />
          </div>
        </Container>

        <DarkFooter />
      </div>
    </>
  );
}

export default Blog;




