import "./post.css";
import Post from "views/examples/Blog/Post.js";
import { Link } from "react-router-dom";
import { UncontrolledTooltip} from "reactstrap";

export default function Posts({ posts }) {
  return (
    <div className="posts">
      <div className="post">
        <Link to={`/write`} className="link">
          <div class ="half">
          <img id="writeIcon" src={require("assets/img/add_post.jpg").default} alt="" />
          </div>
        </Link>
        <UncontrolledTooltip target="#writeIcon">
          Write an entry
        </UncontrolledTooltip>
      </div>
      {posts.map((p) => (
        <Post post={p} />
      ))}
    </div>
  );
}
