import "./blog.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


export default function Sidebar() {
  const [cats, setCats] = useState([]);

  useEffect(() => {
    const getCats = async () => {
      const res = await axios.get("/categories");
      setCats(res.data);
    };
    getCats();
  }, []);
  return (
    <div className="sidebar">
      <div className="sidebarItem">
        <span className="sidebarTitle">INFO</span>
        <img
          src={require("assets/img/logo_B.png").default}
          alt=""
          width="75%"
        />
        <p>
          We would like to collaborate and build a better world. That is why we created this space, to share knowledge.
        </p>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">CATEGORIES</span>
        <ul className="sidebarList">
          <Link to="/blog" className="link">
            <li className="sidebarListItem">All</li>
            </Link>
          {cats.map((c) => (
            <Link to={`/blog?cat=${c.name}`} className="link">
            <li className="sidebarListItem">{c.name}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">FOLLOW US</span>
        <div className="sidebarSocial">
          <a href="https://www.youtube.com/channel/UCFNcSWtfb23a1O0EvyoZjDw"><i className="sidebarIcon fab fa-youtube-square"> </i></a>   
          <a href="https://www.instagram.com/rebots.tk"><i className="sidebarIcon fab fa-instagram-square"></i> </a>
          <a href="https://www.facebook.com/rebots.tk"><i className="sidebarIcon fab fa-facebook-square"> </i> </a>
          <a href="https://www.linkedin.com/company/rebots/"><i className="sidebarIcon fab fa-linkedin-square"> </i></a> 

        </div>
      </div>
    </div>
  );
}
