import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function CompleteExamples() {
  const { t } = useTranslation();

  return (
    <>
      <div className="section" id="losproyectos" data-background-color="dark-blue">
        <Container className="text-center" id="projects">
          
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">{t("Some of our projects.Projects")}</h2>
              <h5>{t("Some of our projects.desc")}</h5>
              <h5 id="underscript" className="description">{t("Some of our projects.desc2")}</h5>
              <h5>&nbsp;</h5>
            </Col>
          </Row>
          
            <div className="row">

              <div className="px-0 col-md-6">
                <div className="card-fashion card-background card align-left"        
                  style={{
                    backgroundImage:
                    "url(" + require("assets/img/carousel/3.jpg").default + ")",
                  }}
                >
              <div className="card-body">
                <div className="text-left card-title">
                  <h2><a href="#welding">
              {t("Some of our projects.Semi-colaborative welding")}
                </a></h2>
              </div>
              <div className="text-left card-footer">
                <div className="stats">
                  <span><i className="now-ui-icons objects_globe"></i>
                  
                  {t("Some of our projects.welding_desc")}
                          
                  </span>
                  </div></div></div></div></div>

          <div className="px-0 col-md-6">
            <div className="card-container">
              <div className="card-fashion card" >
                <div className="card-title">
                  <a href="#ureyes">
                    <h4>
                      
                    {t("Some of our projects.Collaborating with robots")}
                      
                    </h4></a>
                    </div>
                    <div className="card-body">
                      <div className="card-footer">
                        <div className="stats">
                          <span><i className="now-ui-icons education_paper"></i>
                          
                    {t("Some of our projects.ureyes_desc")}
                          
                          </span>
                          </div></div></div></div>
            <div className="card-fashion card-background card align-centre" style={{
                backgroundImage:
                  "url(" + require("assets/img/carousel/robots/vertical/3.jpg").default + ")",
              }}
            >
          </div></div></div></div>
                                    
          <div className="row">
            <div className="px-0 col-md-6">
              <div className="card-container">
                <div className="card-fashion card-background card align-left" style={{
                  backgroundImage:
                  
                  "url(" + require("assets/img/carousel/robots/p0.png").default + ")",
                }} 
                >
                  </div>
                  <div className="card-fashion arrow-left card">
                    <div className="card-title">
                      <h4><a href="#binpicking">
            {t("Some of our projects.bin-picking")}
                        </a></h4>
                      </div>
                      <div className="card-body">
                        <div className="card-footer">
                      <div className="stats"><span><i className="now-ui-icons design_app"></i>
            {t("Some of our projects.bin-picking_desc")}
                      </span>
          </div></div></div></div></div></div>

        <div className="px-0 col-md-6"><div className=" card-fashion card-background card align-left" style={{
          backgroundImage:
            "url(" + require("assets/img/carousel/robots/2.jpg").default + ")",
        }} 
        >
          <div className="card-body">
            <div className="text-left card-title">
              <h2><a href="#recycling">
          {t("Some of our projects.Smart recycling")}
                </a></h2></div><div className="text-left card-footer">
                <div className="stats">
                  <span><i className="now-ui-icons media-2_sound-wave"></i>
          {t("Some of our projects.recylcing_desc")}
                  </span>
          </div></div></div></div></div></div>
          </Container>
          </div>
    </>
  );
}

export default CompleteExamples;
