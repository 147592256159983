import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/carousel/robots/vertical/1.jpg").default,
    altText: "IIWA LBR14 + 3FG15",
    caption: "IIWA LBR14 + 3FG15",
  },
  {
    src: require("assets/img/carousel/robots/vertical/2.jpg").default,
    altText: "YASKAWA HC10 + VG10",
    caption: "YASKAWA HC10 + VG10",
  },
  {
    src: require("assets/img/carousel/robots/vertical/3.jpg").default,
    altText: "UR3 + EYES + SG-A-H",
    caption: "UR3 + EYES + SG-A-H",
  },
  {
    src: require("assets/img/carousel/robots/vertical/4.jpg").default,
    altText: "UR5e + VISION",
    caption: "UR5e + VISION",
  },
  {
    src: require("assets/img/carousel/robots/vertical/5.jpg").default,
    altText: "UR10 + TPS420i",
    caption: "UR10 + TPS420i",
  },
  {
    src: require("assets/img/carousel/robots/vertical/6.jpg").default,
    altText: "KUKA LWR-4",
    caption: "KUKA LWR-4",
  },
  {
    src: require("assets/img/carousel/robots/vertical/7.jpg").default,
    altText: "KUKA KR10 + RG2",
    caption: "KUKA KR10 + RG2",
  },
  {
    src: require("assets/img/carousel/robots/vertical/8.jpg").default,
    altText: "BIN PICKING + CONVEYOR",
    caption: "BIN PICKING + CONVEYOR",
  },
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const { t } = useTranslation();
  return (
    <>
    
      <div className="section" data-background-color="lightgrey" id="carousel">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col id="carousel_text" lg="4">
              <div id="mid-text">
              <h2 className="title">{t("Our Robots")}</h2>
              <h5 className="description">
              {t("Our vision.desc")}
              </h5>
              <h5 className="description">
              {t("Our vision.desc2")}
              </h5>
              </div>
            </Col>
            <Col id="carousel_prop" lg="8" md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <LazyLoadImage 
                        effect="blur"
                        src={item.src} 
                        alt={item.altText} 
                      />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="ayx-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="ayx-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CarouselSection;
