import React, { useRef, useEffect } from "react";
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import {  Button, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col,} from "reactstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";



function AboutUs() {
  const { t } = useTranslation();
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const divRefContact = useRef(null);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if(window.location.href.match("contact")){
      divRefContact.current.scrollIntoView({ behavior: 'smooth' });
    }else{
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function sendEmail(name, email, message) {
    const Swal = require('sweetalert2')
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: email,
        message: message
      })
    };
 
    return fetch("/send", options) //return fetch("https://rebots.tk/about-us", options) 
      .then(res =>{
        if(res.status === 200){
          Swal.fire({
          icon: 'success',
          title: 'Sent!',
          })
          window.location="https://rebots.tk/about-us";
        }else{
          Swal.fire({
          icon: 'error',
          title: 'Error, something went wrong! Send us an email at info@eurobots.net or rebotstk@gmail.com',
          })
          window.location="https://rebots.tk/contact#ERROR";
          }
        })
  }


  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      {/* <IndexNavbar /> */}
      <div className="wrapper">
        <AboutUsHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="11">
                <h2 className="title">{t("about us.who are we")}</h2>
                <h5 className="description">
                {t("about us.desc")}
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/carousel/sala/4.jpg").default + ")",
                    }}
                  >
  
                  </div>

                </Col>
                <Col md="6">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/carousel/robots/4.jpg").default + ")",
                    }}
                  ></div>

                  
                </Col>

                <Col md="12">
                <p>
                </p>
                <h3>
                {t("about us.showroom")}
                  </h3>
                  <p>
                  {t("about us.training")}
                  </p>
                  <p>
                  {t("about us.objective")}
                  </p>
                  <p>
                  {t("about us.goal")}
                  </p>
                  </Col>

              </Row>
            </div>
          </Container>
        </div>

        <div className="section section-team text-center" data-background-color="midgrey">
          <Container data-background-color="midgrey">
            <h2 className="title">{t("Here is Our team.our team")}</h2>
            <div className="team">
              <Row>
                
                <Col id="worker_info" md="6"> {/*for 3 <Col md="4">*/}
                  <div  className="team-player">
                    <LazyLoadImage
                      alt="..."
                      id="foto_perfil"
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/alex.png").default}
                    ></LazyLoadImage>
                    <h4 id="name_worker">Alex Ye Xu</h4>
                    
                    <p id="puesto" className="category text-info">{t("Here is Our team.posA1")}</p>
                    <p id="puesto" className="category text-info">{t("Here is Our team.posA2")}</p>
                    <p className="description">
                      {t("Here is Our team.descA1")}

                    {/*
                        {" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          links
                        </a>{" "}
                    */}
          

                    </p>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="https://www.instagram.com/crazilex"
                      target="blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="https://www.facebook.com/crazilex"
                      target="blank"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                  </div>
                </Col>
                <Col id="worker_info" md="6">
                  <div  className="team-player">
                    <LazyLoadImage
                      alt="..."
                      id="foto_perfil"
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/unai.jfif").default}
                    ></LazyLoadImage>
                    <h4 id="name_worker">Unai Saez</h4>
                    <p id="puesto" className="category text-info">{t("Here is Our team.posB1")}</p>
                    <p id="puesto" className="category text-info">&nbsp;</p>
                    <p className="description">
                     {t("Here is Our team.descB1")}

                    {/*
                        {" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          links
                        </a>{" "}
                    */}
                    
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-instagram"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                  </div>
                </Col>


              </Row>
            </div>
          </Container>
        </div>


        <div ref={divRefContact} id="contact" className="section section-contact-us text-center">
          <Container>
            <h2 className="title">{t("Want to work with us?")}</h2>
            <p className="description">{t("Your projects are important to us")}</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ayx-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    id="name_input"
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ayx-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="email"
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    id="msg"
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    id="contact"
                    block
                    className="btn-round"
                    color="info"
                    href="#send"
                    onClick={() => sendEmail(document.getElementById('name_input').value, document.getElementById('email').value, document.getElementById('msg').value)}
                    size="lg"
                  >
                    {t("Send message")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default AboutUs;
